@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700');
@import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
@import 'ngx-toastr/toastr';
/* Mandatory */
@import '~ng-wizard/themes/ng_wizard.min.css';
@import '~ng-wizard/themes/ng_wizard_theme_arrows.min.css';
@import '~ng-wizard/themes/ng_wizard_theme_circles.min.css';
@import '~ng-wizard/themes/ng_wizard_theme_dots.min.css';
@import "bootstrap/scss/bootstrap-utilities";

body {
	background: #d6d6d6 url(assets/pattern.jpg) repeat fixed;
	color: #474747;
	font-family: 'Open Sans', sans-serif;
	margin: 0;
	padding: 0;
	overflow-x: hidden;
}

label {
	padding-top: calc(0.375rem + 1px);
	padding-bottom: calc(0.375rem + 1px);
	margin-bottom: 0;
	font-size: inherit;
	line-height: 1.5;

	&:not([normal]) {
		font-weight: 700;
	}
}

.toast-container {
	pointer-events: none;
	position: fixed !important;
	z-index: 999999;
}

.termoUso {
	color: white;
	text-decoration: none;
}

input,
.cursor-pointer {
	cursor: pointer;
}

a.nav-link {
	display: block;
	padding-top: 0.5rem;
	padding-right: 0.5rem;
	padding-bottom: 0.5rem;
	padding-left: 0.5rem;
	color: #0000008c;
	text-decoration: none;
	transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
	font-weight: 700 !important;
	font-size: 22px;
}

a.nav-link small {
	font-size: 15px;
	font-weight: normal;
	position: relative;
	top: -5px;
}

.custom-day {
	text-align: center;
	padding: 0.185rem 0.25rem;
	border-radius: 0.25rem;
	display: inline-block;
	width: 2rem;
}

.custom-day:hover {
	background-color: #e6e6e6;
}

.range {
	background-color: #0275d8;
	color: #fff;
}

h1 {
	color: #fff;
	font-weight: bold;
}

.clear {
	content: "";
	display: table;
	clear: both;
}

.btn.btn-secondary {
	background-color: #b7b7b7;
	color: #444;
	border-color: transparent;
}

.btn.btn-secondary:hover {
	background-color: #b0aeae;
}

.agrupaTopo {
	position: relative;
}

nav.navbar {
	position: absolute;
	width: 100%;
	background: rgba(255, 255, 255, 0.9);
	border-bottom: 5px solid #00cb68;
	z-index: 1;
}

.thead-inverse tr th {
	color: black !important;
}

.no-border-buttom {
	border-bottom: 0px !important;
}

.navbar-nav {
	color: #606062 !important;
}

li.divider {
	display: none;
}

.navbar-brand {
	width: 140px;
}

.navbar-toggler {
	font-size: 1.35rem;
	margin-top: 20px;
	border: 2px solid #0fb27d;
	padding: 0.2rem 0.5rem;
}

.navbar-toggler-icon {
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(22, 117, 86, .7)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
	width: 1.3em;
	height: 1.3em;
}

.navbar-toggler-right {
	right: 0;
}

.navbar-collapse {
	margin-top: 10px;
}

.jumbotron {
	background: #00262f url(assets/arte-topo.jpg) right bottom no-repeat;
	background-size: cover;
	padding: 8.2rem 1rem 2rem !important;
	margin-bottom: 60px;
	border-radius: 0;
}

.agrupaTopo {
	text-align: center;
}

agrupaTopo nav .container {
	text-align: left;
}

.iconeTopo {
	background-image: url(assets/icone-engrenagem.png);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	width: 100px;
	height: 100px;
	margin: auto;
}

.descricaoTopo h1 {
	font-size: 2.2rem;
	font-weight: 700;
	color: #2ace99;
	letter-spacing: -1px;
	margin-bottom: 3px;
	margin-top: 15px;
}

.descricaoTopo p {
	margin-bottom: 0;
}

.jumbotron p {
	color: #fff;
	font-size: 0.87rem;
}

.iconeCard {
	width: 70px;
	height: 70px;
	margin: auto;
	position: relative;
	margin-bottom: 27px;
}

.iconeCard img {
	max-width: 100%;
	max-height: 100%;
	width: auto;
	height: auto;
	position: absolute;
	bottom: 0;
	left: 50%;
	-webkit-transform: translateX(-35px);
	transform: translateX(-35px);
}

div.card {
	background-color: rgba(255, 255, 255, 0.15);
	border-radius: 0;
	border: 2px solid #fff;
	margin-bottom: 30px;
	min-height: 230px;
	-webkit-box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.23);
	box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.23);
	overflow: hidden;
	transition: 0.2s;
	cursor: pointer;
}

div.card .card-footer {
	background-color: rgba(255, 255, 255, 0.15);
}

div.card .card-title {
	font-size: 1.4rem;
	letter-spacing: -1px;
	color: #13b17e;
	overflow: hidden;
}

.background-green {
	background: #13b17e
}

div.card p {
	color: #5d5d5d;
	line-height: 1.4rem;
	letter-spacing: -0.2px;
}

div.card:hover {
	background-color: rgba(255, 255, 255, 0.33);
	-webkit-box-shadow: 3px 3px 7px 4px rgba(0, 0, 0, 0.15);
	box-shadow: 3px 3px 7px 4px rgba(0, 0, 0, 0.15);
}

.card-block {
	padding: 2rem;
}

.thead-inverse th {
	background-color: #383838;
	color: #fff;
}

.table tbody tr {
	background-color: #fff;
}

.table-striped tbody tr:nth-of-type(odd) {
	background-color: #fff;
}

.table-hover tbody tr:hover {
	background-color: #efefef;
}

.table td .btn {
	background-color: #d5d5d5;
	border-color: transparent;
	color: #333;
}

.table td .btn.btn-danger {
	color: #fff;
	background-color: #dc3545;
	border-color: #dc3545;
}

.table td .btn.btn-primary {
	color: #fff;
	background-color: #0d6efd;
	border-color: #0d6efd;
}

.table td .btn-primary.disabled,
.table td .btn-primary:disabled {
	color: #333;
}

footer {
	margin-top: 100px;
	font-size: 0.88rem;
}

footer>div:first-child {
	background-color: #0b523b;
	padding: 70px 0;
}

footer .logos {
	text-align: center;
}

footer .logos img {
	display: inline-block;
	height: 55px;
	vertical-align: middle;
	margin-bottom: 20px;
}

footer .logos>img:first-child {
	display: block;
	margin: 0 auto 20px;
}

footer .contato {
	text-align: center;
	margin-top: 10px;
}

footer .contato p {
	color: #fff;
	margin-bottom: 0;
}

footer .contato strong {
	color: #00ea9e;
	display: block;
}

footer .contato i {
	margin-right: 8px;
	text-align: right;
}

footer>div:last-child {
	background-color: #023323;
	padding: 12px 0;
}

footer div.logos.sgi img {
	height: 40px;
	margin-bottom: 0;
	filter: alpha(opacity=50);
	opacity: 0.5;
	-webkit-transition: 0.3s;
	transition: 0.3s;
}

footer div.logos.sgi img:hover {
	filter: alpha(opacity=100);
	opacity: 1;
}

footer div.logos.sgi img:first-child {
	margin-right: 30px;
}

.cg-busy-default-sign {
	border: 1px solid #d8d8d8;
	border-radius: 4px;
	top: 40%;
}

dl dt {
	margin: 0;
}

dl dd {
	margin: 0;
}

.form-control::-webkit-input-placeholder {
	color: #bac1c4;
	opacity: 1;
}

.form-control::-moz-placeholder {
	color: #bac1c4;
	opacity: 1;
}

.form-control:-ms-input-placeholder {
	color: #bac1c4;
	opacity: 1;
}

.form-control::placeholder {
	color: #bac1c4;
	opacity: 1;
}

.has-error {
	border: 1px solid #f00 !important;
}

.no-error {
	border: 1px solid #19ff8f !important;
}

.div-center {
	margin: 0 auto;
}

.my-card {
	border: 2px solid white;
	box-shadow: 2px 2px 5px #888888;
}

.form-block {
	display: inline-block;
	width: 140px;
	text-align: right;
}

.form-block-text {
	width: 140px;
	text-align: right;
}

.dropdox-wizard {
	z-index: 99;
}

.input-append,
.input-prepend {
	margin-bottom: 5px;
	font-size: 0;
	white-space: nowrap;
}

.input-prepend .add-on,
.input-prepend .btn {
	margin-right: -1px;
}

.input-append .add-on,
.input-prepend .add-on,
.input-append .btn,
.input-prepend .btn,
.input-append .btn-group>.dropdown-toggle,
.input-prepend .btn-group>.dropdown-toggle {
	vertical-align: top;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	border-radius: 0;
}

.input-append .add-on,
.input-prepend .add-on {
	display: inline-block;
	width: auto;
	height: 38px;
	min-width: 16px;
	padding: 8px 8px;
	font-size: 17px;
	font-weight: normal;
	line-height: 20px;
	text-align: center;
	text-shadow: 0 1px 0 #ffffff;
	background-color: #eeeeee;
	border: 1px solid #ccc;
}

.input-mini {
	width: 90px !important;
	height: 38px;
	margin-right: 0px !important;
	border-radius: 0;
}

.input-small {
	width: 110px !important;
	height: 38px;
	margin-right: 0px !important;
	border-radius: 0;
}

.input-append .add-on:last-child,
.input-append .btn:last-child,
.input-append .btn-group:last-child>.dropdown-toggle {
	-webkit-border-radius: 0 4px 4px 0;
	-moz-border-radius: 0 4px 4px 0;
	border-radius: 0 4px 4px 0;
}

.input-prepend.input-append .add-on:last-child,
.input-prepend.input-append .btn:last-child {
	margin-left: -1px;
	-webkit-border-radius: 0 4px 4px 0;
	-moz-border-radius: 0 4px 4px 0;
	border-radius: 0 4px 4px 0;
}

.input-prepend.input-append .add-on:first-child,
.input-prepend.input-append .btn:first-child {
	margin-right: -1px;
	-webkit-border-radius: 4px 0 0 4px;
	-moz-border-radius: 4px 0 0 4px;
	border-radius: 4px 0 0 4px;
}

.input-prepend .add-on:first-child,
.input-prepend .btn:first-child {
	-webkit-border-radius: 4px 0 0 4px;
	-moz-border-radius: 4px 0 0 4px;
	border-radius: 4px 0 0 4px;
}

.ng-wizard-not-footer .ng-wizard-theme-arrows .ng-wizard-toolbar {
	padding: 0 !important;
}

.badge.badgeIE{
	background-color: #5cb85c !important;
}

.badge.badgePropriedade{
	background-color: #5cb85c !important;
	white-space: normal;
}

.fichaFormWizard > div.tab-pane.step-content{
	padding: 1.5em;
	background-color: #FFF;
}

.fichaForm>.fichaFormLayout {
	background-color: #FFF;
	border: .05em solid #5cb85c;
	border-radius: .5em;
}
.ng-wizard-theme-arrows>ul.step-anchor>li>a {
	min-height: 85px!important;
}

.fichaWizard .ng-wizard-theme-arrows>ul.step-anchor>li>a[href="#step-1"] {
	display: flex;
	align-items: center;
}

.wizard .ng-wizard-theme-arrows>ul.step-anchor>li>a {
	display: flex;
	align-items: center;
}

.wizard .ng-wizard-theme-arrows>.ng-wizard-container{
	min-height: inherit;
}

/*-------------------------------------------------------------------
                        Media Queries
-------------------------------------------------------------------*/

@media only screen and (max-width: 479px) {
	.widget_content {
		display: none;
	}

	.active .widget_content {
		display: inline;
	}

	.widget h2 {
		border-bottom: 1px solid #ddd;
	}

	.widget h2:after {
		content: "\203A";
		color: white;
		width: 20px;
		height: 20px;
		background: #999;
		display: inline-block;
		text-align: center;
		float: right;
	}

	.active.widget h2:after {
		content: "\2039";
		background: #ccc;
	}
}

@media only screen and (max-width: 575px) {
	div.card {
		max-width: 350px;
	}

	.navbar .container {
		width: 100%;
	}
}

@media only screen and (max-width: 991px) {
	.ng-wizard-theme-arrows>ul.step-anchor {
		border: 0 !important;
		background: #ddd !important;
		flex-wrap: wrap !important;
		display: flex;
	}

	.ng-wizard-theme-arrows>.nav-tabs>li {
		float: none !important;
		margin-bottom: 0 !important;
		flex: 1 0 fit-content;
	}

	li.hidden {
		display: none !important
	}

	.ng-wizard-theme-arrows>ul.step-anchor>li>a,
	.ng-wizard-theme-arrows>ul.step-anchor>li>a:hover {
		margin: 0 !important;
	}

	.ng-wizard-theme-arrows>ul.step-anchor>li>a:after,
	.ng-wizard-theme-arrows>ul.step-anchor>li>a:before {
		display: none !important;
	}
}


@media only screen and (min-width: 356px) {
	footer .logos>img:first-child {
		margin: 0 30px 20px 0;
		display: inline;
	}
}

@media only screen and (min-width:576px) {

	.navbar-brand {
		margin-left: 0;
	}

	.navbar-collapse {
		margin-top: 0;
	}

	li.divider {
		display: inline;
		padding: 0.5em 1.1em;
	}

	div.card {
		height: 250px;
	}

	div.card-block {
		padding: 1rem;
	}

	.badge.badgeIE{
		font-size: 1rem;
	}
	.badge.badgePropriedade{
		font-size: 1rem;
	}
}

@media only screen and (min-width: 768px) {
	.agrupaTopo {
		text-align: left;
	}

	.iconeTopo {
		width: 140px;
		height: 140px;
		display: inline-block;
		vertical-align: middle;
		margin-right: 30px;
	}

	.descricaoTopo {
		display: inline-block;
		vertical-align: middle;
	}

	.descricaoTopo h1 {
		font-size: 2.8rem;
		margin-top: 0;
	}
}

@media only screen and (min-width: 1200px) {
	div.card {
		height: 250px;
	}

	footer .logos {
		float: left;
	}

	footer .logos img {
		margin-bottom: 0;
	}

	footer .logos>img:first-child {
		margin: 0 30px 0 0;
		display: inline;
	}

	footer .contato {
		float: right;
		margin-top: 0;
		padding: 0;
	}

	footer .contato p {
		margin-top: -3px;
		text-align: left;
	}

	footer div.logos.sgi {
		float: right;
	}
}

// @import "compass/css3";

// Reference from: http://maximilianhoffmann.com/posts/better-font-rendering-on-osx
@mixin font-smoothing($value: on) {
	@if $value ==on {
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}

	@else {
		-webkit-font-smoothing: subpixel-antialiased;
		-moz-osx-font-smoothing: auto;
	}
}

$label-size: 30px;
$label-margin: 16px;
$label-before-size: 40px;

$color-light: white;
$color-brand: #146c43;
$color-font: rgba(black, .54);

$font-size-md: 14px;
$font-size-lg: 20px;
$font-size-xl: 54px;
$font-light: 300;
$font-normal: 400;
$font-bold: 500;

.title {
	font-size: $font-size-xl;
	font-weight: $font-light;
	margin-bottom: 54px;

	small {
		font-size: 16px;
	}
}

.link {
	display: block;
	color: $color-font;
	margin-top: 54px;
}

.checkbox-input {
	display: none;
}

.checkbox-label,
.checkbox-text,
.checkbox-text--description {
	transition: all .4s ease;
}

.checkbox-label {
	display: inline-block;
	vertical-align: top;
	position: relative;
	width: 100%;
	padding: $label-size*.5 $label-size;
	cursor: pointer;
	font-size: $font-size-lg;
	font-weight: $font-normal;
	margin: $label-margin 0;
	border: 1px solid darken($color-light, 15%);
	border-radius: 2px;
	box-shadow: inset 0 0 0 0 $color-brand;
	text-align: center;
	border-radius: 5px;

	&:hover {
		-webkit-box-shadow: 0px 0px 15px 4px rgba(0, 0, 0, 0.25);
		box-shadow: 0px 0px 15px 4px rgba(0, 0, 0, 0.25);
	}

	&:before {
		content: "✓";
		position: absolute;
		top: 75%;
		right: 16px;
		width: $label-before-size;
		height: $label-before-size;
		opacity: 0;
		background-color: $color-brand;
		background-image: url(http://lorenzodianni.io/codepen/icon/done--white.svg);
		background-position: center;
		background-repeat: no-repeat;
		background-size: 24px;
		border-radius: 50%;
		transform: translate(0%, -50%);
		transition: all .4s ease;
		color: $color-light;
		padding: 5px;
	}
}

.checkbox-text {
	&--title {
		font-weight: $font-bold;
	}

	&--description {
		font-size: $font-size-md;
		margin-top: 16px;
		padding-top: 16px;
		border-top: 1px solid $color-brand;

		.un {
			display: none;
		}
	}
}

.checkbox-input:checked+.checkbox-label {
	border-color: $color-brand;
	box-shadow: inset 0 -12px 0 0 $color-brand;

	&:before {
		top: 0;
		opacity: 1;
	}

	& .checkbox-text {
		transform: translate(0, -8px);

		&--description {
			border-color: darken($color-light, 15%);

			.un {
				display: inline-block;
			}
		}
	}
}

@media screen and (min-width: 540px) {
	.checkbox-label {
		width: 100%;
		margin: $label-margin;
	}
}

.icon-sefaz {
	opacity: .8;
	font-size: .75em;
	letter-spacing: .1em;
}

.custom-control.material-switch {
	--color: #26a69a;
	padding-left: 0;
}

.custom-control.material-switch .material-switch-control-input {
	display: none;
}

.custom-control.material-switch .material-switch-control-input:checked~.material-switch-control-indicator::after {
	background-color: var(--color);
	left: 17px;
}

.custom-control.material-switch .material-switch-control-indicator {
	display: inline-block;
	position: relative;
	margin: 0 10px;
	top: 4px;
	width: 32px;
	height: 16px;
	background: #ddd;
	border-radius: 16px;
	transition: 0.3s;
}

.custom-control.material-switch .material-switch-control-indicator::after {
	content: '';
	display: block;
	position: absolute;
	width: 18px;
	height: 18px;
	border-radius: 50%;
	transition: 0.3s;
	top: -1px;
	left: -1px;
	background: #fdfdfd;
	box-shadow: 0 2px 10px #aaa;
}

.custom-control.ios-switch {
	--color: var(--bs-primary);
	padding-left: 0;
}

.custom-control.ios-switch .ios-switch-control-input {
	display: none;
}

.custom-control.ios-switch .ios-switch-control-input:active~.ios-switch-control-indicator::after {
	width: 20px;
}

.custom-control.ios-switch .ios-switch-control-input:checked~.ios-switch-control-indicator {
	border: 10px solid var(--color);
}

.custom-control.ios-switch .ios-switch-control-input:checked~.ios-switch-control-indicator::after {
	top: -8px;
	left: 4px;
}

.custom-control.ios-switch .ios-switch-control-input:checked:active~.ios-switch-control-indicator::after {
	left: 0px;
}

.custom-control.ios-switch .ios-switch-control-indicator {
	display: inline-block;
	position: relative;
	margin: 0 10px;
	top: 4px;
	width: 32px;
	height: 20px;
	background: #fff;
	border-radius: 16px;
	transition: 0.3s;
	border: 2px solid #5693f0;
}

.custom-control.ios-switch .ios-switch-control-indicator::after {
	content: '';
	display: block;
	position: absolute;
	width: 16px;
	height: 16px;
	border-radius: 16px;
	transition: 0.3s;
	top: 0px;
	left: 0px;
	background: #5693f0;
	box-shadow: 0 0 2px #aaa, 0 2px 5px #999;
}

.custom-control.ios-switch .ios-switch-control-input:checked~.ios-switch-control-indicator::after {
	background: #fff;
}

.custom-control.border-switch {
	--color: #4cd964;
	padding-left: 0;
}

.custom-control.border-switch .border-switch-control-input {
	display: none;
}

.custom-control.border-switch .border-switch-control-input:checked~.border-switch-control-indicator {
	border-color: var(--color);
}

.custom-control.border-switch .border-switch-control-input:checked~.border-switch-control-indicator::after {
	left: 14px;
	background-color: var(--color);
}

.custom-control.border-switch .border-switch-control-indicator {
	display: inline-block;
	position: relative;
	margin: 0 10px;
	top: 4px;
	width: 32px;
	height: 20px;
	background: #fff;
	border-radius: 16px;
	transition: 0.3s;
	border: 2px solid #ccc;
}

.custom-control.border-switch .border-switch-control-indicator::after {
	content: '';
	display: block;
	position: absolute;
	width: 12px;
	height: 12px;
	border-radius: 50%;
	transition: 0.3s;
	top: 2px;
	left: 2px;
	background: #ccc;
}

.custom-control.teleport-switch {
	--color: #4cd964;
	padding-left: 0;
}

.custom-control.teleport-switch .teleport-switch-control-input {
	display: none;
}

.custom-control.teleport-switch .teleport-switch-control-input:checked~.teleport-switch-control-indicator {
	border-color: var(--color);
}

.custom-control.teleport-switch .teleport-switch-control-input:checked~.teleport-switch-control-indicator::after {
	left: -14px;
}

.custom-control.teleport-switch .teleport-switch-control-input:checked~.teleport-switch-control-indicator::before {
	right: 2px;
	background-color: var(--color);
}

.custom-control.teleport-switch .teleport-switch-control-indicator {
	display: inline-block;
	position: relative;
	margin-right: 10px;
	top: 4px;
	width: 32px;
	height: 20px;
	background: #fff;
	border-radius: 16px;
	transition: 0.3s;
	border: 2px solid #ccc;
	overflow: hidden;
}

.custom-control.teleport-switch .teleport-switch-control-indicator::after {
	content: '';
	display: block;
	position: absolute;
	width: 12px;
	height: 12px;
	border-radius: 50%;
	transition: 0.3s;
	top: 2px;
	left: 2px;
	background: #ccc;
}

.custom-control.teleport-switch .teleport-switch-control-indicator::before {
	content: '';
	display: block;
	position: absolute;
	width: 12px;
	height: 12px;
	border-radius: 50%;
	transition: 0.3s;
	top: 2px;
	right: -14px;
	background: #ccc;
}

.accordion-body {
	padding: 0.75rem 0.35rem !important;
}

.offcanvas-coordinates {

	&.offcanvas {

		&.show {
			visibility: visible;
			z-index: 1055 !important;
		}
	}

	&.offcanvas-900 {
		width: 900px !important;
	}
}

.modal-coordinates {
	&.show {
		display: block;
		z-index: 1055 !important;
	}
}

.origin-canvas {
	height: 100vh;
	width: 100vw;
}

.origin-video {
	height: 100vh;
	width: auto !important;
}

.modo-paisagem {
	.origin-video {
		height: 100vw;
		width: auto !important;
	}
}

.ng-wizard-not-footer .nav-tabs .nav-link:focus,
.ng-wizard-not-footer .nav-tabs .nav-link:hover {
	isolation: inherit !important;
}

.offcanvas-width {
	width: 600px !important;
}

